@use 'sass:color';

@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/sizes';

.background {
  background-color: colours.$off-white;
  border-color: colours.$off-black;
  border-style: solid;
  border-width: 2px;
  border-radius: 24px;
  margin-top: 10px;
}

@media screen and (min-width: sizes.$sm) {
  .newPill {
    background-color: #3b9578;
    border-radius: 8px;
    width: 68px;
    height: 19px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 156px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

@media screen and (max-width: sizes.$sm) {
  .newPill {
    background-color: #3b9578;
    border-radius: 8px;
    width: 68px;
    height: 19px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 198px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.pillText {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  justify-content: center;
  letter-spacing: -0.01em;
  color: #fff9f2;
}

.titleBackground {
  background-color: colours.$off-black;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 8px 0;
  min-height: 25px;
  text-align: center;
}

.title {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.descriptionContent {
  flex-direction: column;
  display: flex;
  padding: 0 20px;
}

.planDescriptionTitle {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #6b6c7b;
  margin-top: 6px;
  margin-bottom: 6px;
}

.inclusionsText {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #1e202e;
  margin-top: 6px;
  text-align: center;
}

.programDescriptionModal {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  max-width: 315px;

  li {
    @include fonts.h6;
    @include fonts.medium;
    color: colours.$blueberry-half;
    margin: 8px 0;
    display: flex;

    &::before {
      content: url('../../../../assets/icons/checkmark.svg');
      display: inline-block;
      margin-right: 0.75em;
    }
  }
}
