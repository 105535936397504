@use 'sass:color';

@use '../foundation/colours';
@use '../foundation/fonts';

.form,
form {
    label,
    .valid-feedback,
    .invalid-feedback {
        @include fonts.label;
        color: colours.$blueberry;
    }

    .valid-feedback {
        color: colours.$salad;
        display: none;
    }

    .invalid-feedback {
        color: colours.$error-red;
        display: none;
    }
    .is-invalid {
        label {
            color: colours.$error-red;
        }

        .invalid-feedback {
            display: block;
        }

        .form-control {
            border: 1px solid colours.$error-red;
            color: colours.$error-red;
        }
    }
    .label-feedback {
        display: flex;
        justify-content: space-between;

        margin-bottom: 8px;
    }

    .form-control {
        @include fonts.h6;
        @include fonts.medium;

        background-color: colours.$white;
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 16px 24px;
        border: 1px solid colours.$cream;
        border-radius: 16px;
        transition: border 0.15s ease-in-out, padding 0.15s ease-in-out;
        outline: none;

        &:focus {
            border: 1px solid colours.$off-black;
        }

        &::placeholder {
            color: color.adjust(colours.$off-black, $alpha: -0.5);
        }
    }
    .mb-3{
        margin-bottom: 16px;
    }
    

    .form-group {
        margin-bottom: 16px;

        &.is-valid {
            label {
                color: colours.$salad;
            }

            .valid-feedback {
                display: block;
            }

            .form-control {
                padding: 15px 23px;
                border: 2px solid colours.$salad;
            }
        }

        
    }

    .input-group {
        display: flex;
        width: 100%;

        .eye {
            width: 0;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            right: 40px;

            &.opaque {
                opacity: 0.5;
            }
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill::first-line,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    textarea:-webkit-autofill::first-line,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    select:-webkit-autofill::first-line {
        @include fonts.h6;
        @include fonts.medium;

        -webkit-text-fill-color: colours.$off-black;
        -webkit-box-shadow: 0 0 0 1000px colours.$cream-half inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}