@use 'sass:color';

@use '../../assets/scss/foundation/colours';

.button {
  align-items: center;
  height: 56px;
  width: 56px;
  cursor: pointer;
  padding: 0px;

  &:disabled {
    background-color: color.adjust(colours.$salad, $alpha: -0.6);
    cursor: not-allowed;
  }

  .icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: url('../../assets/icons/arrow-right.svg') center no-repeat;
  }
}
