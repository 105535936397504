// Import Foundation

@use './foundation/colours';
@use './foundation/sizes';
@use './foundation/fonts';

// Import Bootstrap Override

@use 'bootstrap/button';
@use 'bootstrap/form';
@use 'bootstrap/alert';

@use 'content/wrapper';
@use 'content/section';
@use 'content/card';
@use 'content/cardLabel';

// Font Face Declarations

@font-face {
    font-family: 'Averta';
    src: url('../fonts/averta/averta-regular.woff2') format('woff2'),
    url('../fonts/averta/averta-regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Averta';
    src: url('../fonts/averta/averta-semibold.woff2') format('woff2'),
    url('../fonts/averta/averta-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Averta';
    src: url('../fonts/averta/averta-bold.woff2') format('woff2'),
    url('../fonts/averta/averta-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Averta';
    src: url('../fonts/averta/averta-extrabold.woff2') format('woff2'),
    url('../fonts/averta/averta-extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

// Lexend Fonts Declarations

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/lexend-regular.ttf') format('truetype'),
    url('../fonts/lexend/lexend-regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/lexend-semibold.ttf') format('truetype'),
    url('../fonts/lexend/lexend-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/lexend-bold.ttf') format('truetype'),
    url('../fonts/lexend/lexend-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/lexend-extrabold.ttf') format('truetype'),
    url('../fonts/lexend/lexend-extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

// Generic Font Styling

html, body {
    margin: unset;
    padding: unset;
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;

    font-family: Lexend, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: subpixel-antialiased;

    font-size: 16px;
    line-height: 1.625em;
    font-style: normal;
    font-weight: 600;
    vertical-align: middle;

    color: colours.$off-black;
}

.h1,
h1 {
    @include fonts.h1;
}

.h2,
h2 {
    @include fonts.h2;
}

.h3,
h3 {
    @include fonts.h3;
}

.h4,
h4 {
    @include fonts.h4;
}

.h5,
h5 {
    @include fonts.h5;
}

.h6,
h6 {
    @include fonts.h6;
}

.callout {
    @include fonts.callout;
}

.subhead {
    @include fonts.subhead;
}

.caption {
    @include fonts.caption;
}

.label {
    @include fonts.label;
}

strong,
.bold {
    @include fonts.bold;
}

.medium {
    @include fonts.medium;
}

.regular {
    @include fonts.regular;
}

.swiper{
    padding-bottom: 25px !important;
}
.swiper-pagination{
    bottom: 0px !important;
}