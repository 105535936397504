@use 'sass:color';

@use '../../../assets/scss/foundation/colours';
@use '../../../assets/scss/foundation/fonts';

.planQuantityCounter {
  box-sizing: border-box;
  width: 100%;
  padding: 16px 24px;
  border-radius: 16px;
  background-color: colours.$cream-half;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;

  .left {
    display: flex;
    flex-direction: column;

    .upper {
      @include fonts.h6;
      @include fonts.medium;
      color: colours.$off-black;
      align-items: center;

      .infoButton {
        position: relative;
        vertical-align: middle;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background: url('../../../assets/icons/info.svg') no-repeat center /
          contain;
        margin-left: 0.5rem;

        &:hover,
        &:active,
        &:focus {
          .tooltip {
            display: block;
          }
        }

        .tooltip {
          display: none;
          position: absolute;
          bottom: 200%;
          left: 50%;

          .inner {
            @include fonts.callout;

            position: relative;
            left: -50%;
            width: 100vw;
            max-width: 270px;
            padding: 1rem;
            border-radius: 0.5rem;
            background-color: colours.$off-black;
            color: colours.$off-white;
            z-index: 1;

            &::after {
              z-index: 0;
              content: '';
              position: absolute;
              bottom: -0.4rem;
              left: calc(50% - 0.5rem);
              width: 1rem;
              height: 1rem;
              border-radius: 0 0 0.2rem 0;
              background-color: colours.$off-black;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .price {
      @include fonts.subhead;
      color: colours.$blueberry-half;

      .red {
        color: colours.$salad;
      }
    }
  }

  .counter {
    display: flex;
    align-items: center;

    .counterValue {
      @include fonts.h6;
      @include fonts.medium;
      color: colours.$salad;

      box-sizing: border-box;
      margin: 0 8px;
      width: 40px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid colours.$cream;
      background-color: colours.$white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      width: 40px;
      height: 40px;
      border-radius: 12px;
      padding: 0;
      border: 0;

      &:not(:disabled) {
        color: colours.$salad;
        background-color: color.adjust(colours.$salad, $alpha: -0.9);
      }
    }
  }
}
