@use 'sass:color';

@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/fonts';

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 18px;
}

.planTitleSelected {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.6px;
  color: colours.$salad;
}

.planTitle {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.6px;
  color: #6b6c7b;
}

.borderBottom {
  border-bottom-style: solid;
  border-bottom-color: colours.$orange-half;
  width: 50%;
  text-align: center;
  cursor: pointer;
}

.borderBottomSelected {
  border-bottom-style: solid;
  border-bottom-color: colours.$salad;
  width: 50%;
  text-align: center;
  cursor: pointer;
}

.eightWeekMinimum {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #6b6c7b;
}
