@use 'sass:color';

@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/fonts';

.extrasCounter {
  box-sizing: border-box;
  width: 100%;
  padding: 16px 24px;
  border-radius: 16px;
  background-color: colours.$cream-half;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
  min-height: 82px;

  .left {
    display: flex;
    flex-direction: column;

    .upper {
      @include fonts.h6;
      @include fonts.medium;
      color: colours.$off-black;
    }

    .price {
      @include fonts.subhead;
      color: colours.$blueberry-half;

      .red {
        color: colours.$salad;
      }
    }
  }

  .counter {
    display: flex;
    align-items: center;

    .counterValue {
      @include fonts.h6;
      @include fonts.medium;
      color: colours.$salad;

      box-sizing: border-box;
      margin: 0 8px;
      width: 40px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid colours.$cream;
      background-color: colours.$white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      width: 40px;
      height: 40px;
      border-radius: 12px;
      padding: 0;
      border: 0;
      color: colours.$white;
      background-color: color.adjust(colours.$cream, $alpha: -0.5);

      &:not(:disabled) {
        background-color: colours.$cream;
        color: colours.$salad;
      }
    }
  }
}
