@use '../foundation/colours';
@use '../foundation/sizes';

.contentCard {
    box-sizing: border-box;
    width: 100%;
    padding: 16px 24px;
    border-radius: 16px;
    background-color: colours.$cream-half;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    button {
        flex-shrink: 0;
    }

    &.lighter {
        background-color: colours.$off-white;
    }
}