// NEW COLORS:
$cucumber: #116A48;

$salad: #01965E;
$salad-half: #C1F0DF;

$orange: #F58C35;
$orange-half: #F9BF8F;

$blue: #3DA5D9;
$deep-blue: #1F5C8C;

$blueberry: #555768;
$blueberry-half: #6B6C7B;

$cream: #FFDFB4;
$cream-half: #FFEEDA;

$off-black: #101820;
$black: #000000;

$off-white: #FCF9EF;
$white: #FFFFFF;

$utility-green: #3B9578;

$error-red: #E54639;
