@use '../../../assets/scss/foundation/colours';
@use '../../../assets/scss/foundation/fonts';

body {
  counter-reset: steps;
}

.stepLabel {
  @include fonts.label;
  @include fonts.medium;
  color: colours.$blueberry-half;

  display: flex;
  align-items: center;

  &::before {
    counter-increment: steps;
    content: counter(steps);
    display: inline-block;
    width: 13px;
    height: 13px;
    line-height: 13px;
    font-size: 9px;
    border: 1.75px solid colours.$blueberry-half;
    border-radius: 10px;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
  }

  &.completed {
    color: colours.$salad;

    &::before {
      content: url('../../../assets/icons/success.svg');
      width: 17px;
      height: 17px;
      border: 0;
      line-height: normal;
      border-radius: 0;
      margin-right: 10px;
    }
  }
}
