.backButton {
    height: 56px;
    width: 56px;
    border-radius: 50% !important;

    .icon {
        width: 24px;
        height: 24px;
    }
}
