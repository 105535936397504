@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/fonts';

.container {
  display: flex;
  flex: 1;
  padding: 4px 16px;
  border-radius: 12px;
  align-items: center;
  background-color: colours.$cream-half;

  .title {
    @include fonts.subhead;
    font-weight: 400;
    padding: 8px;
    color: colours.$blueberry-half;
  }
}
