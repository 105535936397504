@use 'sass:color';

@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/sizes';

.background {
  background-color: color.adjust(colours.$off-black, $alpha: -0.5);

  @media screen and (max-width: sizes.$sm) {
    background-color: colours.$off-white;
  }
}

.accountPopup {
  position: relative;

  @media screen and (max-width: sizes.$sm) {
    position: static;
  }

  .link {
    font-family: Lexend, sans-serif;
    font-size: 0.75rem !important;
    line-height: 1.0625rem !important;
    margin-top: 0px !important;
    font-style: normal;
    font-weight: 600;
    color: #e54639;
    display: inline;
  }

  .close {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    top: 20px;
    right: 20px;
    padding: 0 !important;
    border: none;
    background: url('../../../../assets/icons/cross.svg') center/16px 16px
      no-repeat;

    &:hover,
    &:active,
    &:focus {
      background-color: colours.$cream;
    }
  }

  h3 {
    margin-bottom: 32px;
    text-align: center;
  }
}
