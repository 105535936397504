@keyframes spin {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.2);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}

.loader {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    .spin {
        animation-name: spin;
        animation-duration: 1.5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
}
