@use 'sass:color';

@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/fonts';

.container {
  display: flex;
  flex: 1;
  padding-inline: 24px;
  border-radius: 12px;
  background-color: colours.$off-white;
  border: 2px solid transparent;
  margin-bottom: 20px;
  transition: 0.3s ease;

  .buttonStyle {
    padding-block: 20px;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .title {
    @include fonts.h5;
    @include fonts.medium;
    font-weight: 700;
    color: colours.$off-black;
    text-align: left;
  }

  .onlyTitle {
    @include fonts.h6;
    @include fonts.medium;
    font-weight: 600;
    color: colours.$off-black;
    text-align: left;
  }

  .subTitle {
    @include fonts.subhead;
    font-weight: 400;
    color: colours.$blueberry-half;
    text-align: left;
  }
}

.selected {
  border: 2px solid colours.$salad;
  transition: 0.3s ease;
}
