@use 'sass:color';

@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/sizes';

@media screen and (max-width: sizes.$lg) {
  .wrapper {
    background:
      url('../../../../assets/images/backgrounds/bg-shape-2.svg') bottom
        left/auto 60% no-repeat,
      colours.$cream;
  }
}
.innerWrapper {
  flex-direction: column;
  align-items: normal !important;
}
.mainRow {
  flex: 1;
  flex-direction: row;
  display: flex;
  width: 100%;
  @media screen and (max-width: sizes.$md) {
    flex-direction: column-reverse;
    max-width: 420px;
  }
}
.cardColumn {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #cbcbcb;
  padding: 0px 4% 0px 0px;
  @media screen and (max-width: sizes.$md) {
    border-right: 0px;
    padding-top: 20px;
    border-top: 1px solid #cbcbcb;
  }
}
.pageTitle {
  @include fonts.h3;
  margin: 0 auto 5%;
  @include fonts.medium;
}
