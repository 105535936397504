@use 'sass:color';

@use '../../../assets/scss/foundation/fonts';
@use '../../../assets/scss/foundation/colours';
@use '../../../assets/scss/foundation/sizes';

.accountMenu {
  position: relative;
  max-width: 240px;
  margin-right: 64px;
  background: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    @include fonts.h6;
    @include fonts.medium;
    color: colours.$blueberry;

    width: 100%;
    box-sizing: border-box;
    padding: 16px 24px;
    border-radius: 16px;
    text-decoration: none;

    &.active {
      color: colours.$salad;
      background-color: color.adjust(colours.$salad, $alpha: -0.9);
    }
  }

  .close {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    top: 10px;
    right: 14px;
    padding: 0 !important;
    margin: 0 !important;
    border: none;
    background: url('../../../assets/icons/arrow.svg') center/16px 8px no-repeat;
    display: none;

    &:hover,
    &:active,
    &:focus {
      background-color: color.adjust(colours.$off-black, $alpha: -0.95);
    }
  }

  @media screen and (max-width: sizes.$lg) {
    max-width: none;
    background: colours.$cream;
    margin-bottom: 32px;

    a {
      color: colours.$blueberry-half;
      border-radius: 24px;

      &.active {
        color: colours.$off-black;
        background: color.adjust(colours.$off-black, $alpha: -0.95);
      }
    }

    .close {
      display: block;
    }

    &.closed {
      .active {
        background: none;
      }

      a:not(.active) {
        display: none;
      }

      .close {
        transform: rotate(180deg);
      }
    }
  }
}
