@use '../../../assets/scss/foundation/fonts';
@use '../../../assets/scss/foundation/colours';

.cardLabel {
    @include fonts.callout;
    @include fonts.medium;
    color: colours.$blueberry;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}