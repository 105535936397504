@use 'sass:color';

@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/sizes';

.walletContainer {
    background-color: colours.$white;
    flex: 1;
    border-radius: 10px;
    margin-left: 10px;
    padding: 20px;
    @media screen and (max-width: sizes.$md) {
        margin-bottom: 20px;
        margin-left: 0px;
        width: 90%;
        padding: 5%;
    }
}

.or {
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    margin: 10px 0px;
}

.or:after,
.or:before {
    content: "";
    display: block;
    background: grey;
    width: 30%;
    height: 1px;
    margin: 0 10px;
}
