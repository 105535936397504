.toastWrapper {
    position: absolute;
    width: 100%;
    top: 0;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;

    div {
        pointer-events: auto;
        cursor: pointer;
        max-width: 400px;
        max-height: 200px;
        opacity: 1;
        transition: opacity 0.2s ease, max-height 0.5s ease;

        &.hidden {
            max-height: 0;
            opacity: 0;
        }
    }
}
