@use '../../../../../assets/scss/foundation/colours';
@use '../../../../../assets/scss/foundation/fonts';
@use '../../../../../assets/scss/foundation/sizes';
@use 'sass:color';

.cardLabel {
  margin-bottom: 12px;

  .cancelButton a {
    @include fonts.regular;
    text-decoration: underline;
    color: colours.$salad;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: colours.$cucumber;
    }
  }
}

.card {
  margin-bottom: 32px;
}

.background {
  background-color: color.adjust(colours.$off-black, $alpha: -0.5);

  @media screen and (max-width: sizes.$sm) {
    background-color: colours.$off-white;
  }
}
.redTrialText {
  color: colours.$cucumber;
}

.updateSubscriptionWarning {
  position: relative;

  @media screen and (max-width: sizes.$sm) {
    position: static;
  }

  .close {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    top: 20px;
    right: 20px;
    padding: 0;
    border: none;
    background: url('../../../../../assets/icons/cross.svg') center/16px 16px
      no-repeat;

    &:hover,
    &:active,
    &:focus {
      background-color: colours.$cream;
    }
  }

  .bodytext {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #555768;
    margin-bottom: 20px;
  }

  .title {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 20px;
  }
}
