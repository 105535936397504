@use 'sass:color';

@use 'src/assets/scss/foundation/colours';
@use 'src/assets/scss/foundation/fonts';
@use 'src/assets/scss/foundation/sizes';

.orderColumn {
  flex: 1;
  padding: 0px 0px 0px 4%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  .orderDetailContainer {
    background-color: colours.$off-white;
    border-radius: 20px;
    min-width: 340px;
    @media screen and (max-width: sizes.$sm) {
      background-color: colours.$cream-half;
      min-width: 240px;
    }
  }
  .orderColumnTitle {
    @include fonts.h5;
    margin: 0 auto 20px;
  }
  .orderTitle {
    background: colours.$orange-half;
    padding: 5px 20px;
    @include fonts.callout;
    text-transform: capitalize;
    border-radius: 20px 20px 0px 0px;
  }
  .orderBreakdown {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: sizes.$sm) {
      flex-direction: column;
    }
  }
  .orderCell {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .monthDetail {
    flex-direction: column;
    padding: 20px 10px;
    align-items: start;
    min-width: 170px;
  }
  .monthText {
    @include fonts.callout;
    text-transform: capitalize;
    @include fonts.bold;
    min-width: 80px;
  }
  .pricingContainer {
    @include fonts.h6;
    @include fonts.regular;
  }
  .price {
    @include fonts.h4;
  }
  .mediumPrice {
    @include fonts.h5;
  }
  .subscriptionDesc {
    @include fonts.subhead;
    @include fonts.regular;
  }
  .changeButton {
    padding: 10px;
  }
  .planDetailContainer {
    display: flex;
    flex-direction: row;
  }
}
