@use 'sass:color';

@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/sizes';

.walletContainer {
    background-color: colours.$white;
    flex: 1;
    border-radius: 10px;
    margin-left: 10px;
    padding: 20px;
    @media screen and (max-width: sizes.$md) {
        margin-bottom: 20px;
        margin-left: 0px;
        width: 90%;
        padding: 5%;
    }
}
.mainDiv {
    text-align: center;
    background: white;
    border-radius: 10px;
    border: 1px solid #eeeeee;
}
.mainContainer {
    text-align: center;
}
