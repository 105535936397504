@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/sizes';

.header {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  padding: 0 32px;

  .inner {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 200px;
      min-width: 110px;
      width: 30%;

      &:only-child {
        margin: 0 auto;
      }
    }
  }

  .logo {
    width: 100% !important;
    height: 32px;
  }

  @media screen and (max-width: sizes.$sm) {
    padding: 0 24px;
  }
}
