@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/fonts';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  // margin-bottom: 20px;

  .label {
    @include fonts.label;
    font-weight: 400;
    color: colours.$blueberry-half;
  }

  .inputContainer {
    display: flex;
    border-radius: 8px;
    margin-top: 8px;
    position: relative;

    .input {
      display: flex;
      flex: 1;
      height: 48px;
      // width: 330px;
      @include fonts.callout;
      font-weight: 600;
      color: colours.$black;
      background-color: colours.$off-white;
      border-radius: 5px;
      padding-inline: 12px;
      padding-left: 22px;
      border: none;
      outline: none;
    }
    .input::placeholder {
      color: gray;
      font-weight: 400;
      font-size: 14px;
    }

    .eyeButton {
      position: absolute;
      padding: 8px;
      top: 0;
      bottom: 0;
      right: 8px;
    }

    .iconOff {
      opacity: 0.5;
    }
  }

  .error {
    border: 1px solid colours.$error-red;
    padding: 6px;
  }

  .warningContainer {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    margin-top: 8px;

    .warningIcon {
      height: 18px;
      width: 18px;
    }

    .errorText {
      @include fonts.subhead;
      font-weight: 400;
      color: colours.$salad;
    }
  }
}
