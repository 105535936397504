@use '../../../assets/scss/foundation/fonts';
@use '../../../assets/scss/foundation/colours';
@use '../../../assets/scss/foundation/sizes';

@media screen and (min-width: sizes.$md) {
  .imageWrapper {
    background: url('../../../assets/images/backgrounds/bg-fajita.png') left
      center/auto 100% no-repeat;
  }
}

.confirmation {
  display: flex;

  .imageWrapper {
    width: 40%;

    .imageInnerWrapper {
      height: 100vh;
      padding: 120px 0;
      justify-content: flex-end;

      .imageFlex {
        width: 0;
        height: 100%;
        overflow: visible;
        display: flex;
        justify-content: center;

        img {
          height: 100%;
          width: auto;
        }
      }
    }

    @media screen and (max-width: sizes.$md) {
      display: none;
    }
  }

  .wrapper {
    @media screen and (max-width: sizes.$md) {
      background: url('../../../assets/images/backgrounds/beef-ragu.png') bottom -2px
        left 80px / auto 30% no-repeat;
    }

    .innerWrapper {
      @media screen and (min-width: sizes.$md) {
        padding-left: 200px;
      }

      .card {
        background-color: transparent;
        max-width: 570px;
        padding: 0;

        h2 {
          margin-top: 0;
          margin-bottom: 16px;
        }

        .welcome {
          @include fonts.h6;
          @include fonts.medium;

          color: #e54639;
        }

        .download {
          @include fonts.h5;
          @include fonts.regular;

          color: colours.$blueberry-half;
        }

        .appLinks {
          display: flex;
          margin-top: 32px;

          .appLink {
            width: 164px;

            img {
              width: 100%;
            }

            &:not(:last-child) {
              margin-right: 32px;

              @media screen and (max-width: sizes.$sm) {
                margin-right: 16px;
              }
            }
          }

          @media screen and (max-width: sizes.$sm) {
            .appLink {
              &:not(:last-child) {
                margin-right: 16px;
              }
            }
          }
        }
        .socialIconLinks {
          display: flex;
          background-color: #fff9f2;
          width: 200px;
          height: 70px;
          border-radius: 100px;
          justify-content: space-around;
          align-items: center;
        }
        .socialIconLink {
          margin-top: 5px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
