@use 'sass:color';

@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/colours';

.noneSelected {
  @include fonts.subhead;
  color: colours.$blueberry-half;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.paymentPreview {
  display: flex;
  margin-right: 10px;

  .cardBrandIcon {
    display: none;
    width: 43px;
    height: 43px;
    border-radius: 25px;
    background: color.adjust(colours.$salad, $alpha: -0.9)
      url('../../../../assets/icons/user.svg') center no-repeat;
    margin-right: 16px;

    &.visa {
      display: initial;
      background: url('../../../../assets/icons/cards/Visa.svg') center
        no-repeat;
    }

    &.mastercard {
      display: initial;
      background: url('../../../../assets/icons/cards/Mastercard.svg') center
        no-repeat;
    }

    &.discover {
      display: initial;
      background: url('../../../../assets/icons/cards/Discover.svg') center
        no-repeat;
    }

    &.amex {
      display: initial;
      background: url('../../../../assets/icons/cards/Amex.svg') center
        no-repeat;
    }
  }

  .card {
    .brandNumber {
      @include fonts.subhead;
      color: colours.$off-black;

      .big {
        @include fonts.h6;
        @include fonts.medium;
        text-transform: capitalize;
      }
    }

    .expiry {
      @include fonts.subhead;
      color: colours.$blueberry-half;
    }
  }
}
