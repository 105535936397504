@use 'sass:color';

@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/fonts';

.counter {
  display: flex;
  align-items: center;

  .value {
    @include fonts.h6;
    @include fonts.medium;
    color: colours.$salad;
  }

  button,
  .buttonBox {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .decrementButton {
    background-color: colours.$cream;
  }
  .decrementButton:disabled {
    background-color: color.adjust(colours.$cream, $alpha: -0.5);
  }

  .valueBox {
    background-color: colours.$white;
    margin-inline: 8px;
  }

  .incrementButton {
    background-color: colours.$cream;
  }
  .incrementButton:disabled {
    background-color: color.adjust(colours.$cream, $alpha: -0.5);
  }
}
