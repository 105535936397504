@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/fonts';

.container {
  height: 4px;
  border-radius: 2px;
  position: relative;
  background-color: colours.$white;

  .progress {
    position: absolute;
    left: 10;
    height: 4px;
    border-radius: 2px;
    background-color: colours.$salad;
  }

  .indicator {
    position: absolute;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: colours.$cucumber;
  }
}

.title {
  @include fonts.h6;
  font-weight: 600;
  color: colours.$salad;
  text-align: center;
  width: 100%;
  margin-bottom: 8px;
}
