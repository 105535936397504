@use '../../../../../assets/scss/foundation/fonts';
@use '../../../../../assets/scss/foundation/colours';

.noneSelected {
  @include fonts.subhead;
  color: colours.$blueberry-half;
  display: flex;
  align-items: center;
}

.changeSubscriptionPreview {
  display: flex;
  flex-direction: column;

  .price {
    @include fonts.subhead;
    color: colours.$off-black;

    .big {
      @include fonts.h6;
      @include fonts.medium;
    }
  }

  .nextDate {
    @include fonts.subhead;
    color: colours.$blueberry;

    .red {
      color: colours.$salad;
    }
  }
  .billingDate {
    @include fonts.subhead;
  }
}
