@use 'sass:color';

@use '../../../assets/scss/foundation/sizes';
@use '../../../assets/scss/foundation/colours';
@use '../../../assets/scss/foundation/fonts';

.userButton {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    color: colours.$blueberry-half;

    .arrow {
      width: 16px;
      height: 8px;
      background: url('../../../assets/icons/arrow.svg') center/16px 8px
        no-repeat;
      display: inline-block;
      transform: rotate(180deg);
    }

    @media screen and (max-width: sizes.$sm) {
      display: none;
    }
  }

  .userIcon {
    width: 43px;
    height: 43px;
    border-radius: 25px;
    background: color.adjust(colours.$salad, $alpha: -0.9)
      url('../../../assets/icons/user.svg') center no-repeat;
    margin-left: 16px;

    transition: all 0.2s ease;
  }

  .userMenu {
    position: absolute;
    top: 110%;
    right: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    background: colours.$white;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    a {
      @include fonts.h6;
      @include fonts.medium;

      text-decoration: none;
      color: colours.$blueberry-half;

      &:hover {
        color: colours.$blueberry;
      }

      &.red {
        color: colours.$salad;

        &:hover {
          color: colours.$cucumber;
        }
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    .userIcon {
      background: color.adjust(colours.$salad, $alpha: -0.8)
        url('../../../assets/icons/user.svg') center no-repeat;
    }
  }
}
