@use 'sass:color';

@use '../foundation/colours';
@use '../foundation/sizes';
@use '../foundation/fonts';

.btn {
    @include fonts.h6;
    @include fonts.medium;
    color: colours.$white;

    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: colours.$salad;
    border: 2px solid colours.$salad;
    padding: 16px 32px;
    border-radius: 100px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        background-color: colours.$cucumber;
        border-color: colours.$cucumber;
        box-shadow: none;
        outline: none;
    }

    &.btn-outline-secondary {
        color: colours.$salad;
        background-color: transparent;

        &:hover,
        &:active,
        &:focus {
            background-color: color.adjust(colours.$salad, $alpha: -0.9);
        }
    }

    &.disabled,
    &:disabled {
        color: colours.$white;
        background-color: color.adjust(colours.$salad, $alpha: -0.7);
        border: none;
        // background-color: colours.$cream;
        // border-color: colours.$cream;
        opacity: 1;
    }

    &.btn-lg {
        font-size: 1.25rem;
        line-height: 1.75rem;
        padding: 20px 32px;
        box-sizing: border-box;
        display: block;
        width: 100%;

        @media screen and (max-width: sizes.$sm) {
            font-size: 1rem;
            line-height: 1.625rem;
            padding: 16px 32px;
        }
    }

    &.btn-sm {
        font-size: 0.75rem;
        line-height: 1.0625rem;
        padding: 12px 16px;
    }

    &:not(:disabled),
    &:not(.disabled) {
        cursor: pointer;
    }

    &.btn-block {
        box-sizing: border-box;
        display: block;
        width: 100%;
    }
    &.w-100{
        width: 100%;
    }
}
