@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/fonts';

.numberInput {
  width: 164px;
  border-radius: 12px;
  background-color: colours.$off-white;
  padding-block: 8px;
  padding-inline: 24px;

  .label {
    @include fonts.subhead;
    font-weight: 400;
    color: colours.$blueberry-half;
  }

  .input {
    display: block;
    @include fonts.h6;
    font-weight: 400;
    color: colours.$blueberry-half;
    height: 30px;
    margin-block: 4px;
    border: none;
    outline: none;
    background: transparent;
  }
}
