@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/fonts';

.layoutContainer {
  position: relative;
  background: colours.$cream-half;
  min-height: 100vh;

  .circleBackground {
    background: colours.$cream;
    position: absolute;
    height: 930px;
    width: calc(100vw + 400px);
    border-radius: 50%;
    top: -35%;
    left: -200px;
  }
  .darkCircleBackground {
    background-color: #2b2d42;
    position: absolute;
    height: calc(100vh + 180px);
    width: calc(100vw + 400px);
    border-radius: 50%;
    top: -200px;
    left: -200px;
  }

  .logoLink {
    position: absolute;
    top: 20px;
    left: 90px;

    @media (max-width: 600px) {
      left: 10px;
    }
  }

  .childrenContainer {
    display: flex;
    position: relative;
  }
}
