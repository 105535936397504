@use 'sass:color';

@use '../../../assets/scss/foundation/colours';
@use '../../../assets/scss/foundation/fonts';

.plans {
  margin-top: 32px;
  padding-bottom: 16px;

  .plan {
    box-sizing: border-box;
    width: 100%;
    padding: 14px 22px;
    border-radius: 16px;
    border: 2px solid colours.$cream-half;
    background-color: colours.$cream-half;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    transition: border 0.1s ease;

    .left {
      display: flex;
      flex-direction: column;

      .price {
        @include fonts.h6;
        @include fonts.medium;
        color: colours.$off-black;

        .big {
          @include fonts.h5;
          @include fonts.medium;
        }
      }

      .priceName {
        @include fonts.h5;
        @include fonts.medium;
        font-weight: 700;
        color: colours.$salad;
      }

      .weeklyPrice {
        @include fonts.subhead;
        color: colours.$blueberry-half;

        .bestValue {
          @include fonts.caption;

          margin-right: 3px;
          padding: 1px 6px 2px;
          background-color: #3b9578;
          color: colours.$off-white;
          border-radius: 8px;
        }

        .red {
          color: colours.$salad;
        }
      }
    }

    .tick {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: color.adjust(colours.$off-black, $alpha: -0.9);
      transition: background-color 0.1s ease;
    }

    &.selected {
      border: 2px solid colours.$salad;
      background: color.adjust(colours.$salad, $alpha: -0.95);

      .tick {
        background: colours.$salad
          url('../../../assets/icons/checkmark-bold-white.svg') center no-repeat;
      }
    }
  }
}
