@use 'src/assets/scss/foundation/fonts';
@use 'src/assets/scss/foundation/colours';

.container {
  display: flex;
  margin-left: 20px;
  margin-bottom: 20px;
  transition: 0.3s ease;

  .buttonStyle {
    background-color: colours.$off-white;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    border: 1.5px solid colours.$orange-half;
    padding: 10px;
  }

  .title {
    font-size: 12px;
    font-weight: 400;
    color: colours.$blueberry-half;
  }

  .selectedTitle {
    font-size: 12px;
    font-weight: 600;
    color: colours.$off-black;
  }
}
.reverseOrderStyle {
  margin-right: 20px;
  margin-left: 0px;
}
.extraPaddingLeftStyle {
  padding-left: 68px;
}
.extraPaddingRightStyle {
  padding-right: 68px;
}

.selected {
  border: 1.5px solid colours.$salad !important;
  transition: 0.3s ease;
}
