@use '../foundation/colours';
@use '../foundation/sizes';

@mixin mobileSection {
    box-sizing: border-box;
    max-width: none;
    height: 100%;
    border-radius: 0;
    padding: 0;

    &:not(:last-child) {
        margin-bottom: 24px;
    }
}

.contentSection {
    padding: 48px;
    max-width: 440px;
    width: 100%;
    border-radius: 24px;
    background-color: colours.$off-white;

    &.contentSection-sm {
        @media screen and (max-width: sizes.$sm) {
            @include mobileSection;
        }
    }

    &.contentSection-md {
        margin-bottom: 20px;
        @media screen and (max-width: sizes.$md) {
            @include mobileSection;
        }
    }

    &.contentSection-lg {
        min-height: 400px;

        @media screen and (max-width: sizes.$lg) {
            @include mobileSection;
        }
    }

    &.contentSection-xl {
        @media screen and (max-width: sizes.$xl) {
            @include mobileSection;
        }
    }
}
