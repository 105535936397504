@use '../../assets/scss/foundation/fonts';
@use '../../assets/scss/foundation/colours';

.container {
  display: flex;
  gap: 60px;

  .card {
    display: flex;
    padding: 15px 21px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 15px;
    background: colours.$off-white;

    .logoImage {
      width: 144px;
      height: 38px;
    }

    .qrImage {
      width: 144px;
      height: 144px;
    }
  }
}
