@use 'sass:color';

@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/sizes';

.accountExtras {
  display: contents;

  h3 {
    margin-top: 0;

    @media screen and (max-width: sizes.$lg) {
      display: none;
    }
  }

  .field {
    margin-bottom: 32px;

    .label {
      @include fonts.label;
      color: colours.$blueberry-half;
      margin-bottom: 10px;
    }

    .inner {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .text {
        @include fonts.h6;
        @include fonts.medium;
        color: colours.$off-black;
      }

      .button {
        @include fonts.callout;
        @include fonts.medium;
        color: colours.$blueberry-half;
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon {
          width: 14px;
          height: 14px;
          margin-right: 8px;
          opacity: 0.9;

          &.email {
            background: url('../../../../assets/icons/email.svg') center
              no-repeat;
          }

          &.password {
            background: url('../../../../assets/icons/lock.svg') center
              no-repeat;
          }
        }

        &:hover,
        &:active,
        &:focus {
          color: colours.$off-black;

          .icon {
            opacity: 1;
          }
        }
      }
    }
  }
}
