@use 'sass:color';

@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/sizes';

.background {
  background-color: color.adjust(colours.$off-black, $alpha: -0.5);

  @media screen and (max-width: sizes.$sm) {
    background-color: colours.$off-white;
  }
}

.cardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;

  @media screen and (max-width: sizes.$sm) {
    position: static;
  }

  .close {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    top: 20px;
    right: 20px;
    padding: 0 !important;
    margin: 0 !important;
    border: none;
    background: url('../../../../assets/icons/cross.svg') center/16px 16px
      no-repeat;

    &:hover,
    &:active,
    &:focus {
      background-color: colours.$cream;
    }
  }

  .creditText {
    @include fonts.subhead;
  }

  h3 {
    margin-bottom: 32px;
    text-align: center;
  }

  .activeTab {
    border: 1px solid #aaaaaa;
  }

  .cardTabs {
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: colours.$white;
    align-items: center;
    padding: 20px 10px;
    border-radius: 10px;
    height: 45px;
    flex-direction: column;
    text-align: center;
    margin: 5px;
    cursor: pointer;

    @media screen and (max-width: sizes.$sm) {
      width: 90%;
      padding: 5%;
    }

    .creditCardIcon {
      width: 20px;
      height: 14px;
      background: color.adjust(colours.$salad, $alpha: -0.9)
        url('../../../../assets/icons/cards/creditCard.svg') center no-repeat;
      margin-bottom: 5px;
    }

    .afterPayIcon {
      width: 115px;
      height: 50px;
      background: url('../../../../assets/icons/afterpayFullLogo.svg') center
        no-repeat;
    }

    .cardBrand {
      width: 34px;
      height: 24px;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &.visa {
        background: url('../../../../assets/icons/cards/Visa.svg') center
          no-repeat;
      }

      &.mastercard {
        background: url('../../../../assets/icons/cards/Mastercard.svg') center
          no-repeat;
      }

      &.discover {
        background: url('../../../../assets/icons/cards/Discover.svg') center
          no-repeat;
      }

      &.amex {
        background: url('../../../../assets/icons/cards/Amex.svg') center
          no-repeat;
      }

      &.selected {
        opacity: 1 !important;
      }
    }

    &.active {
      .cardBrand {
        opacity: 0.5;
      }
    }
  }

  button {
    margin-top: 32px !important;
    padding: 16px 32px !important;
    min-width: 300px;
  }

  .cardMetaDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .cardExpiryDateContainer {
    display: block;
    flex: 1;
  }

  .cardSecurityCodeContainer {
    margin-left: 20px;
    flex: 1;

    @media screen and (max-width: sizes.$sm) {
      margin-left: 0px;
    }
  }

  .input {
    background-color: colours.$white;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 16px 24px;
    border: 1px solid colours.$cream;
    border-radius: 16px;
    transition:
      border 0.15s ease-in-out,
      padding 0.15s ease-in-out;
    outline: none;

    &:focus {
      padding: 15px 23px;
      border: 2px solid colours.$off-black;
    }

    &.invalid {
      padding: 15px 23px;
      border: 2px solid colours.$error-red;
    }
  }

  .paymentMethodsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    margin-bottom: 20px;

    @media screen and (max-width: sizes.$md) {
      // flex-direction: column-reverse;
    }
  }

  .walletContainer {
    background-color: colours.$white;
    flex: 1;
    border-radius: 10px;
    margin-left: 10px;
    padding: 20px;

    @media screen and (max-width: sizes.$sm) {
      margin-bottom: 20px;
      margin-left: 0px;
      width: 90%;
      padding: 5%;
    }
  }
}
