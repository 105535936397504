@use '../../assets/scss/foundation/fonts';
@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/sizes';

@media screen and (min-width: sizes.$lg) {
  .wrapper {
    background:
      url('../../assets/images/backgrounds/bg-shape.svg') bottom left/auto 60%
        no-repeat,
      colours.$off-white;
  }
}

.account {
  .card {
    background-color: adjust-color(colours.$cream-half, $alpha: -0.3);

    @media screen and (max-width: sizes.$lg) {
      background-color: transparent;
    }
  }
}
