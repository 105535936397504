@use '../../assets/scss/foundation/fonts';
@use '../../assets/scss/foundation/colours';

.resetPassword {
  background-color: colours.$cream-half;
  h3 {
    margin-top: 0;
    text-align: center;
  }

  .eye {
    width: 0;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    right: 40px;

    &.opaque {
      opacity: 0.5;
    }
  }

  .button {
    padding: 16px 32px !important; // Not usually okay with using important, but this will only apply to the correct element due to modular css classes.
  }

  a {
    @include fonts.medium;
    display: block;
    font-size: 0.875rem;
    color: colours.$blueberry;
    margin-top: 16px;
    text-align: center;
    cursor: pointer;
  }
}
