@use 'sass:color';

@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/fonts';

.checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  // margin-bottom: 32px;

  .input {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: none;
    padding: 0;
    border: 0;
    margin-right: 16px;

    background-color: color.adjust(colours.$off-black, $alpha: -0.9);

    &.checked {
      background: url('../../assets/icons/checkmark-bold-white.svg') center/11px
        10px no-repeat;
      background-color: colours.$salad;
    }
    &.checked.greenColor {
      background: url('../../assets/icons/checkmark-bold-white.svg') center/11px
        10px no-repeat;
      background-color: colours.$utility-green;
    }
  }

  .text {
    flex: 1;
    @include fonts.subhead;
    color: colours.$blueberry-half;

    a {
      @include fonts.subhead;
      color: colours.$salad;
      display: inline;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.checkbox_with_grey_text {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  .input {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: none;
    padding: 0;
    border: 0;
    margin-right: 16px;

    background-color: color.adjust(colours.$off-black, $alpha: -0.9);

    &.checked {
      background: url('../../assets/icons/checkmark-bold-white.svg') center/11px
        10px no-repeat;
      background-color: colours.$salad;
    }
  }

  .text {
    @include fonts.subhead;
    color: colours.$off-black;

    a {
      @include fonts.subhead;
      color: colours.$salad;
      display: inline;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
