@use '../../../assets/scss/foundation/sizes';
@use '../../../assets/scss/foundation/colours';

.textButton {
  display: flex;

  p {
    color: colours.$salad;

    @media screen and (max-width: sizes.$sm) {
      display: none;
    }
  }

  button {
    margin-left: 20px;
    padding: 12px 32px;

    @media screen and (max-width: sizes.$sm) {
      font-size: 0.75rem;
      line-height: 1.0675rem;
      padding: 12px 16px !important;
    }
  }
}
