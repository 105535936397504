@use '../../../../../assets/scss/foundation/colours';
@use '../../../../../assets/scss/foundation/fonts';

.cardLabel {
  margin-bottom: 12px;

  .cancelButton {
    @include fonts.regular;
    text-decoration: underline;
    color: colours.$salad;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: colours.$cucumber;
    }
  }
}

.card {
  margin-bottom: 32px;
}
