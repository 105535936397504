@use '../foundation/colours';
@use '../foundation/fonts';
@use 'sass:color';

.alert {
    @include fonts.regular;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.0675rem;
    color: colours.$blueberry;

    padding: 12px 16px;
    border-radius: 12px;
    margin-bottom: 16px;

    background: colours.$cream;

    &.alert-secondary {
        background: colours.$white;
    }

    &.alert-success {
        background: adjust-color(#42A180, $alpha: -0.8);
        color: colours.$salad;

        &::before {
            content: url('../../icons/success.svg');
            display: inline-block;
            height: 17px;
            vertical-align: middle;
            line-height: normal;
            margin-right: 10px;
        }
    }

    &.alert-danger {
        background: adjust-color(colours.$error-red, $alpha: -0.8);
        color: colours.$error-red;

        &::before {
            content: url('../../icons/danger.svg');
            display: inline-block;
            height: 17px;
            vertical-align: middle;
            line-height: normal;
            margin-right: 10px;
        }
    }

    &.alert-info {
        background: #E5F6FD;
        &::before {
            content: url('../../icons/info.svg');
            display: inline-block;
            height: 17px;
            vertical-align: middle;
            line-height: normal;
            margin-right: 10px;
        }
    }
}