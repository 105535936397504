@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/colours';

.noneSelected {
  @include fonts.subhead;
  color: colours.$blueberry-half;
  display: flex;
  align-items: center;
}

.subscriptionPreview {
  display: flex;
  flex-direction: column;

  .price {
    @include fonts.subhead;
    color: colours.$off-black;

    .big {
      @include fonts.h6;
      @include fonts.medium;
    }
  }

  .weeklyPrice {
    @include fonts.subhead;
    color: colours.$blueberry-half;
    margin-right: 10px;

    .red {
      color: colours.$salad;
    }
  }
}
