@use 'sass:color';

@use '../../../assets/scss/foundation/colours';
@use '../../../assets/scss/foundation/fonts';

.promoCode {
  margin-bottom: 32px;

  form {
    display: flex;
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 16px;

    .input {
      padding: 8px 0;
      border: 0;
      background: none;
      width: 100%;
      border-radius: 0;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill::first-line {
        -webkit-box-shadow: 0 0 0 1000px colours.$off-white inset;
      }
    }

    .button {
      @include fonts.medium;

      padding: 8px 0;
      margin: 0;
      border: 0;
      background: none;
      text-decoration: underline;
      color: colours.$salad;

      &:disabled {
        color: color.adjust(colours.$off-black, $alpha: -0.5);
      }

      &:not(:disabled) {
        &:active,
        &:hover,
        &:focus {
          color: colours.$cucumber;
        }
      }
    }
  }
  .warningContainer {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    margin-top: -8px;

    .warningIcon {
      height: 18px;
      width: 18px;
    }

    .errorText {
      @include fonts.subhead;
      font-weight: 400;
      color: colours.$error-red;
    }
  }
}
