@use 'sass:color';

@use '../../../../../assets/scss/foundation/colours';
@use '../../../../../assets/scss/foundation/fonts';
@use '../../../../../assets/scss/foundation/sizes';

.background {
  background-color: color.adjust(colours.$off-black, $alpha: -0.5);

  @media screen and (max-width: sizes.$sm) {
    background-color: colours.$off-white;
  }
}

.paymentPopup {
  position: relative;

  @media screen and (max-width: sizes.$sm) {
    position: static;
  }

  .close {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    top: 20px;
    right: 20px;
    padding: 0 !important;
    margin: 0 !important;
    border: none;
    background: url('../../../../../assets/icons/cross.svg') center/16px 16px
      no-repeat;

    &:hover,
    &:active,
    &:focus {
      background-color: colours.$cream;
    }
  }

  h3 {
    margin-bottom: 32px;
    text-align: center;
  }

  .cardBrands {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    .cardBrand {
      width: 34px;
      height: 24px;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &.visa {
        background: url('../../../../../assets/icons/cards/Visa.svg') center
          no-repeat;
      }

      &.mastercard {
        background: url('../../../../../assets/icons/cards/Mastercard.svg')
          center no-repeat;
      }

      &.discover {
        background: url('../../../../../assets/icons/cards/Discover.svg') center
          no-repeat;
      }

      &.amex {
        background: url('../../../../../assets/icons/cards/Amex.svg') center
          no-repeat;
      }

      &.selected {
        opacity: 1 !important;
      }
    }

    &.active {
      .cardBrand {
        opacity: 0.5;
      }
    }
  }

  button {
    margin-top: 32px !important;
    padding: 16px 32px !important;
  }

  .input {
    background-color: colours.$white;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 16px 24px;
    border: 1px solid colours.$cream;
    border-radius: 16px;
    transition:
      border 0.15s ease-in-out,
      padding 0.15s ease-in-out;
    outline: none;

    &:focus {
      padding: 15px 23px;
      border: 2px solid colours.$off-black;
    }

    &.invalid {
      padding: 15px 23px;
      border: 2px solid colours.$orange;
    }
  }
}
