@use 'sass:color';

@use 'src/assets/scss/foundation/colours';
@use 'src/assets/scss/foundation/fonts';
@use 'src/assets/scss/foundation/sizes';

.background {
  background-color: color.adjust(colours.$off-black, $alpha: -0.5);

  @media screen and (max-width: sizes.$sm) {
    background-color: colours.$cream-half;
  }
}

.subscriptionPopup {
  position: relative;
  background-color: colours.$cream-half;

  @media screen and (max-width: sizes.$sm) {
    position: static;
  }

  .close {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    top: 20px;
    right: 20px;
    padding: 0;
    border: none;
    background: url('../../assets/icons/cross.svg') center/16px 16px no-repeat;

    &:hover,
    &:active,
    &:focus {
      background-color: colours.$cream;
    }
  }

  h3 {
    margin-bottom: 16px;
    text-align: center;
  }

  .minTwoMonths {
    @include fonts.callout;
    color: colours.$blueberry-half;
    text-align: center;
  }
}
