@use 'sass:color';

@use '../../../../assets/scss/foundation/colours';
@use '../../../../assets/scss/foundation/fonts';
@use '../../../../assets/scss/foundation/sizes';

.background {
  background-color: color.adjust(colours.$off-black, $alpha: -0.5);

  @media screen and (max-width: sizes.$sm) {
    background-color: colours.$off-white;
  }
}

.loading-component {
  text-align: center;
  padding: 15px 0;
}

.extrasPopup {
  position: relative;

  @media screen and (max-width: sizes.$sm) {
    position: static;
  }

  .close {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    top: 20px;
    right: 20px;
    padding: 0;
    border: none;
    background: url('../../../../assets/icons/cross.svg') center/16px 16px
      no-repeat;

    &:hover,
    &:active,
    &:focus {
      background-color: colours.$cream;
    }
  }

  h3 {
    margin-bottom: 16px;
    text-align: center;
  }

  .minTwoMonths {
    @include fonts.callout;
    color: colours.$blueberry-half;
    text-align: center;
  }
  .field {
    margin-bottom: 32px;

    .label {
      @include fonts.label;
      color: colours.$blueberry-half;
      margin-bottom: 10px;
    }

    .inner {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .text {
        @include fonts.h6;
        @include fonts.medium;
        color: colours.$off-black;
      }

      .price {
        @include fonts.h6;
        @include fonts.medium;
        color: colours.$cucumber;
      }

      .button {
        @include fonts.callout;
        @include fonts.medium;
        color: colours.$blueberry-half;
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon {
          width: 14px;
          height: 14px;
          margin-right: 8px;
          opacity: 0.9;

          &.email {
            background: url('../../../../assets/icons/email.svg') center
              no-repeat;
          }

          &.password {
            background: url('../../../../assets/icons/lock.svg') center
              no-repeat;
          }
        }

        &:hover,
        &:active,
        &:focus {
          color: colours.$off-black;

          .icon {
            opacity: 1;
          }
        }
      }
    }
  }
}
