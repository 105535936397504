@use 'sass:color';

@use 'src/assets/scss/foundation/fonts';
@use 'src/assets/scss/foundation/colours';

.heading {
  @include fonts.h4;
  color: colours.$off-black;
}

.subHeading {
  @include fonts.h5;
  color: colours.$off-black;
  font-weight: 400;
  display: flex;
}

.subTitle {
  @include fonts.h6;
  color: colours.$blueberry-half;
  font-weight: 400;
  display: flex;
}

.stackContainer {
  position: relative;
  width: 100%;
}

.backButtonWrapper {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);

  &_mobile {
    left: 15px;
  }
}

.stepperWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.helperText {
  text-align: center;
  color: #1e202e;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;

  &:visited,
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
