@use '../../assets/scss/foundation/fonts';
@use '../../assets/scss/foundation/colours';
@use '../../assets/scss/foundation/sizes';

@media screen and (min-width: sizes.$sm) {
  .wrapper {
    background:
      url('../../assets/images/backgrounds/bg-shape-2.svg') bottom left/auto 60%
        no-repeat,
      colours.$cream-half;
  }
}

.recommendedPlanText {
  font-size: 30px;
  @media screen and (max-width: sizes.$lg) {
    font-size: 23px !important;
  }
}
.recommendedPlanTextSpan {
  color: #e54639;
  @media screen and (max-width: sizes.$lg) {
    display: inline-block;
    margin-top: 10px;
  }
}
.planContainer {
  padding: 24px;
  background: #fff9f0;
  border: 1.5px solid #e54639;
  border-radius: 24px;
  max-width: 520px;
  margin-top: -64px;
  @media screen and (max-width: sizes.$lg) {
    margin-top: 0px;
  }

  .descriptionText {
    font-style: normal;
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #6b6c7b;
    margin: 0px;
  }
}

.signup {
  .p1 {
    @include fonts.h6;
    @include fonts.medium;
    color: colours.$blueberry-half;
    padding-top: 15px;
  }

  .startDate {
    font-size: 14px;
    padding-bottom: 20px;
    color: colours.$blueberry-half;
  }
  .signUpStartDate {
    font-size: 14px;
    padding-bottom: 20px;
    color: colours.$off-black;
  }
  .startSubscriptionText {
    font-size: 14px;
    padding-bottom: 20px;
    color: colours.$off-black;
    margin-top: -10px;
    font-weight: 400;
  }
  .signUpStartCheckboxes {
    color: colours.$off-black;
  }
  .horizontalRow {
    height: 2px;
    background-color: #d2cecb;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  h4 {
    margin: 0 0 24px 0;

    @media screen and (min-width: sizes.$lg) {
      display: none;
    }
  }
  .leftContentCloseButton {
    position: relative;
    .close {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      top: -7px;
      right: -5px;
      padding: 0 !important;
      margin: 0 !important;
      border: none;
      background: url('../../assets/icons/arrow.svg') center/16px 8px no-repeat;
      display: none;

      &:hover,
      &:active,
      &:focus {
        background-color: colours.$cream;
      }
    }
    @media screen and (max-width: sizes.$lg) {
      .close {
        display: block;
      }

      &.closed {
        ul {
          display: none;
        }

        h3 {
          margin: 0;
        }

        .close {
          transform: rotate(180deg);
        }
      }
    }
  }

  .subscriptionIncludes {
    position: relative;
    padding: 64px 0;
    margin-right: 64px;
    max-width: 500px;
    background: none;

    @media screen and (max-width: sizes.$lg) {
      .close {
        display: block;
      }

      &.closed {
        ul {
          display: none;
        }

        h3 {
          margin: 0;
        }

        .close {
          transform: rotate(180deg);
        }
      }
    }

    .subTitleStandardText {
      font-size: 26px;
      font-weight: 600;
    }
    .programDescription {
      padding: 0;
      margin-left: 0;
      margin-right: 0;
      list-style: none;

      li {
        @include fonts.h6;
        @include fonts.medium;
        max-width: 450px;
        color: colours.$blueberry-half;
        margin: 8px 0;
        display: flex;

        &::before {
          content: url('../../assets/icons/checkmark-green.svg');
          display: inline-block;
          margin-right: 0.75em;
        }
      }
    }

    @media screen and (max-width: sizes.$lg) {
      box-sizing: border-box;
      padding: 16px 24px;
      background: colours.$cream-half;
      border-radius: 16px;
      max-width: none;
      margin-right: 0;

      ul {
        margin-bottom: 0;
      }

      h3 {
        @include fonts.h6;
        margin: 0 0 16px;
      }
    }
  }

  h3 {
    margin-top: 0;
  }

  .button {
    margin-top: 32px;
    padding: 16px 32px !important; // Not usually okay with using important, but this will only apply to the correct element due to modular css classes.
  }

  a {
    @include fonts.medium;
    display: block;
    font-size: 0.875rem;
    color: colours.$blueberry;
    margin-top: 16px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }
  .inlineLink {
    color: colours.$salad !important;
    display: inline !important;
  }
}

.innerWrapper {
  flex-direction: column;
  align-items: normal !important;
  max-width: 1200px;
  margin: 0 auto;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 37px;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: sizes.$lg) {
    flex-direction: column;
  }
  @media screen and (max-width: sizes.$md) {
    align-items: center;
  }
}

.banner {
  position: relative;
  min-width: 930px;
  background: #ffe3bd;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 31px;

  p {
    font-size: 24px;
    font-weight: 800;
    line-height: 31px;
    color: #000;
    margin: 0;

    @media screen and (max-width: sizes.$lg) {
      margin: 0 30px;
    }
  }

  span.tsandcs {
    font-size: 12px;
    line-height: 22px;
    color: #767786;
    // margin: 0 auto;
  }

  @media screen and (max-width: sizes.$lg) {
    min-width: 100%;
    padding: 20px 0;
  }

  .close {
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    top: 10px;
    right: 10px;
    padding: 0;
    border: none;
    background: url('../../assets/icons/cross.svg') center/12px 12px no-repeat;
    background-color: colours.$cream;

    &:hover,
    &:active,
    &:focus {
      background-color: colours.$off-white;
    }
  }
}
